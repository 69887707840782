<template>
  <Card :flex="12" title="Service statement">
    <template v-slot:content>
      <table class="service-statement">
        <thead>
          <tr>
            <th></th>
            <th>Online</th>
            <th>Walk Up</th>
            <th>Table</th>
            <th>Phone</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <RowChannelPaymentType
            name="Customer Payments"
            :total="this.statement.payments"
            :online="this.statement.paymentsOnline"
            :walkup="this.statement.paymentsWalkup"
            :table="this.statement.paymentsTable"
            :phone="this.statement.paymentsPhone" />
          <tr class="title">
            <td><h3>Service Charge</h3></td>
            <td class="red">
              {{ formatMoney(this.statement.serviceCharge.customer * -1) }}
            </td>
            <td></td>
            <td class="red">
              {{ formatMoney(this.statement.serviceCharge.outlet * -1) }}
            </td>
            <td></td>
            <td class="red">
              {{ formatMoney(this.statement.serviceCharge.customer * -1) }}
            </td>
          </tr>
          <RowChannelPaymentType
            name="Gross Revenue"
            :total="this.statement.grossRevenue"
            :online="this.statement.grossRevenueOnline"
            :table="this.statement.grossRevenueTable"
            :walkup="this.statement.grossRevenueWalkup"
            :phone="this.statement.grossRevenuePhone" />
          <tr class="title">
            <td colspan="5"><h3>Promotion Contribution</h3></td>
            <td>
              {{ formatMoney(this.statement.promotionContribution) }}
            </td>
          </tr>
          <tr class="title">
            <td colspan="5"><h3>Refunds</h3></td>
            <td class="red">{{ formatMoney(this.statement.refunds * -1) }}</td>
          </tr>
          <RowCancellations
            name="Cancellations"
            :cancellations="this.statement.cancellations" />
          <tr class="title">
            <td colspan="5"><h3>Adjusted Revenue</h3></td>
            <td>{{ formatMoney(this.statement.adjustedRevenue) }}</td>
          </tr>
          <tr class="title">
            <td colspan="5"><h3>Commissions</h3></td>
            <td class="red">
              {{ formatMoney(this.statement.commissionTotal * -1) }}
            </td>
          </tr>
          <tr class="title">
            <td colspan="5"><h3>Walk Up Transaction Fee</h3></td>
            <td class="red">
              {{
                formatMoney(
                  (this.statement.processingFee.walkup +
                    this.statement.processingFee.phone) *
                    -1
                )
              }}
            </td>
          </tr>
          <tr class="title">
            <td colspan="5"><h3>Total</h3></td>
            <td>
              {{ formatMoney(this.statement.total) }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </Card>
</template>

<script>
  import RowChannelPaymentType from "./RowChannelPaymentType.vue";
  import RowCancellations from "./RowCancellations.vue";
  import Card from "@/components/Card";

  export default {
    components: {
      RowChannelPaymentType,
      RowCancellations,
      Card,
    },

    props: {
      statement: {
        type: Object,
        default: null,
      },
    },
  };
</script>

<style lang="scss">
  table.service-statement {
    width: 100%;
    @include striped-row-table;

    tr {
      width: 100%;

      th,
      td {
        width: 15%;
        text-align: right;

        &:first-child {
          width: 30%;
          text-align: left;

          h3 {
            font-size: 1rem;
            font-weight: bold;
            margin: 0;
          }
        }
      }
    }

    tfoot {
      font-weight: bold;
    }

    .red {
      color: $col_alpha-darker;
    }
  }
</style>
