<template>
  <div class="main-content">
    <div class="centered-loading-wrapper column" v-if="loading">
      <LoadingSpinner />
      <p>Loading service statement...</p>
    </div>
    <div v-else>
      <div class="fixed-header column">
        <BackToPageLink title="Back to service details" @close="goBack()" />
        <div class="row" v-if="this.statement != null">
          <ServiceStatement :statement="this.statement" />
        </div>
        <div class="row" v-else>Failed to load statement.</div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import BackToPageLink from "@/components/BackToPageLink.vue";
  import ServiceStatement from "@/components/ServiceStatements/ServiceStatement.vue";

  export default {
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        serviceId: parseInt(this.$route.params.serviceId),
        loading: false,
        statement: null,
      };
    },

    components: { LoadingSpinner, BackToPageLink, ServiceStatement },

    methods: {
      goBack() {
        this.$router.push({ path: `/service/${this.serviceId}` });
      },
    },

    mounted() {
      this.loading = true;

      store.state.apiPrivate.client.endpoints.statementsServices
        .get(this.serviceId)
        .then((response) => {
          if (response.status >= 200 && response.status <= 204) {
            return response.data.data;
          } else {
            return Promise.reject({
              operator: { type: this.operatorType, id: this.operatorId },
              serviceId: this.serviceId,
              response,
            });
          }
        })
        .then((data) => {
          this.statement = data;
        })
        .catch((ex) => {
          window.log.error("Failed to download service statement.", ex);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  };
</script>
